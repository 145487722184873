import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import Logo from "pages/assets/logos/logo-dark.png";
import LoginImage from "pages/assets/images/login_two.svg";
import axios from "config/axios";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "middlewares/hooks/useAuth";
import ProgressBar from "@badrap/bar-of-progress";
import Footer from "pages/components/footer";
const LoginUser = ({setRoles}) => {
 const [remeber, setRemember] = useState(false);
 const onRemember = () => {
   remeber === true ? setRemember(false) : setRemember(true);
 };
const progress = new ProgressBar({ color: "#020f46", size: 4 });
const navigate = useNavigate();
const location = useLocation();
const { setAuth } = useAuth();
const [disabled, setDisabled] = useState(false);
const from = location.state?.from?.pathname || "/dashboard";
const {
  register,
  handleSubmit,
  formState: { errors },
} = useForm();

const onLoginAction = async (data) => {
  try {
    progress.start();
    setDisabled(true);
    const response = await axios.post("/sessions", data);
    
    const accesstoken = response?.data?.data?.access_token;
    const refreshtoken = response?.data?.data?.refresh_token;
    const sessionid = response?.data?.data?.session_id;
    setAuth({ sessionid, accesstoken, refreshtoken });
    const verify = {
      refresh: refreshtoken,
      id: sessionid,
      access: accesstoken,
    };
    localStorage["verify"] = JSON.stringify(verify);
    localStorage["roles"] = JSON.stringify(response.data?.data?.permissions);
    localStorage['user_info'] = JSON.stringify(response.data?.data?.user_info);
    setRoles(localStorage?.roles);
    navigate(from, { replace: true });
    progress.finish();
  } catch (error) {
    if (!error?.response) {
      setDisabled(false);
      toast.error("No server response", {
        style: { background: errorColor, color: "#000" },
      });
      progress.finish();
    } else {
      setDisabled(false);
      progress.finish();
      toast.error(error?.response?.data?.messages, {
        style: { background: errorColor, color: "#000" },
      });
    }
  }
};
    return (
      <>
        <Toaster />
        <div className="page-wrapper" id="main-wrapper">
          <div className="position-relative overflow-hidden radial-gradient max-vh-100">
            <div className="position-relative z-index-1">
              <div className="row">
                <div className="col-xl-6 col-xxl-7">
                  <Link
                    to="/"
                    className="text-nowrap logo-img d-block px-2 py-0 w-0"
                  >
                    <h6 className="text-dark">Welcome: </h6>
                    <img src={Logo} width="180" alt="logo-dark" />
                  </Link>
                  <div
                    className="d-none d-xl-flex align-items-center justify-content-center"
                    style={{ height: "calc(100vh - 100px)" }}
                  >
                    <img
                      src={LoginImage}
                      alt="login-white"
                      className="img-fluid"
                      width="400"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-xxl-5">
                  <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                    <div className="col-sm-8 col-md-6 col-xl-9">
                      <h6 className="mb-3 fs-6 fw-bolder text-center">
                        Furaha Loans Management System
                      </h6>
                      <h5 className="mb-3 fs-4 fw-bolder text-center">
                       Login
                      </h5>
                      <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onLoginAction)}
                      >
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            aria-describedby="Username"
                            autoComplete="new-password"
                            placeholder="Username"
                            autoFocus={true}
                            {...register("username", {
                              required: true,
                              pattern: { value: /^\S+@\S+$/i },
                            })}
                          />
                          {errors?.username?.type === "required" && (
                            <p className="text-small  m-0 text-danger help-block">
                              Please provide a valid username
                            </p>
                          )}
                          {errors?.username?.type === "pattern" && (
                            <p className="text-small  m-0 text-danger help-block">
                              Please provide a valid username
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            autoComplete="new-password"
                            placeholder="Password"
                            autoFocus={true}
                            {...register("password", { required: true })}
                          />
                          {errors?.password?.type === "required" && (
                            <p className="text-small m-0 text-danger help-block">
                              Please provide a password
                            </p>
                          )}
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div className="form-check">
                            <input
                              className="form-check-input primary"
                              type="checkbox"
                              onChange={onRemember}
                              id="flexCheckChecked"
                              defaultChecked={remeber}
                            />
                            <label
                              className="form-check-label text-dark"
                              htmlFor="flexCheckChecked"
                            >
                              Remember this Device
                            </label>
                          </div>
                          <Link
                            className="text-primary fw-medium"
                            to="/recover/email"
                          >
                            Forgot Password ?
                          </Link>
                        </div>
                        <button
                          disabled={disabled}
                          className="btn btn-primary w-100 py-8 mb-4 rounded-2"
                        >
                          {!disabled && "Sign In"}{" "}
                          {disabled && "Please Wait ....."}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}
 
export default LoginUser;